import React, { useState, useEffect, useRef } from 'react';

const PomodoroClock = () => {
  const [breakLength, setBreakLength] = useState(5);
  const [sessionLength, setSessionLength] = useState(25);
  const [timeLeft, setTimeLeft] = useState(25 * 60);
  const [isSession, setIsSession] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);
  const beepRef = useRef(null);

  useEffect(() => {
    setTimeLeft(sessionLength * 60);
  }, [sessionLength]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleStartStop = () => {
    if (isRunning) {
      clearInterval(timerRef.current);
    } else {
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 0) {
            beepRef.current.play();
            if (isSession) {
              setIsSession(false);
              return breakLength * 60;
            } else {
              setIsSession(true);
              return sessionLength * 60;
            }
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    setIsRunning(!isRunning);
  };

  const handleReset = () => {
    clearInterval(timerRef.current);
    setIsRunning(false);
    setIsSession(true);
    setBreakLength(5);
    setSessionLength(25);
    setTimeLeft(25 * 60);
    beepRef.current.pause();
    beepRef.current.currentTime = 0;
  };

  const handleBreakChange = (increment) => {
    if (!isRunning) {
      setBreakLength((prev) => Math.min(Math.max(prev + increment, 1), 60));
    }
  };

  const handleSessionChange = (increment) => {
    if (!isRunning) {
      const newSessionLength = Math.min(Math.max(sessionLength + increment, 1), 60);
      setSessionLength(newSessionLength);
      setTimeLeft(newSessionLength * 60);
    }
  };

  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <div id="pomodoro-clock">
      <div className="length-controls">
        <div id="break-label">
          <h3>Break Length</h3>
          <button id="break-decrement" onClick={() => handleBreakChange(-1)}>-</button>
          <span id="break-length">{breakLength}</span>
          <button id="break-increment" onClick={() => handleBreakChange(1)}>+</button>
        </div>
        <div id="session-label">
          <h3>Session Length</h3>
          <button id="session-decrement" onClick={() => handleSessionChange(-1)}>-</button>
          <span id="session-length">{sessionLength}</span>
          <button id="session-increment" onClick={() => handleSessionChange(1)}>+</button>
        </div>
      </div>

      <div id="timer">
        <h2 id="timer-label">{isSession ? 'Session' : 'Break'}</h2>
        <div id="time-left">{formatTime(timeLeft)}</div>
      </div>

      <div id="controls">
        <button id="start_stop" onClick={handleStartStop}>
          {isRunning ? 'Pause' : 'Start'}
        </button>
        <button id="reset" onClick={handleReset}>Reset</button>
      </div>
      <audio id="beep" ref={beepRef} src="https://www.soundjay.com/button/beep-07.wav" />
    </div>
  );
};

export default PomodoroClock;
