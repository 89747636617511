
import './App.css';
import PomodoroClock from './PomodoroClock';

function App() {
  return (
    <div className="App">
      <PomodoroClock />
    </div>
  );
}

export default App;
